import axios from "axios";
import {message} from "antd";
import store from "store";

export default class AxiosUtil {

    static AxiosRequest = (url: string, queryParam: {}, callback: (s: boolean, d: any) => void) => {

        const login_info = store.get("login_info");
        const access_token = login_info ? login_info.accessToken || "" : "";
        axios({
            headers: {"accessToken": access_token},
            method: 'post',
            url: url,
            data: queryParam
        })
            .then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    if (response.data.code === 200) {
                        callback(true, response.data.data);

                    } else if (response.data.code === 601) {
                        message.error({content: response.data.msg});
                        window.location.replace('/#/login');
                        callback(false, []);

                    } else {
                        message.error({content: 'Data Error: ' + response.data.msg});
                        //window.location.replace('/#/login');
                        callback(false, response.data.msg);

                    }
                } else {
                    message.error({content: 'Response Error: ' + response.statusText});
                    callback(false, []);
                }

            })
            .catch((error) => {
                console.log('Axios :' + error);
                message.error({content: <span>Axios {error.toString()} <br/>post: {url}</span>});
                callback(false, []);
                //_this.setState({showLoading: false});
            });
    }
}