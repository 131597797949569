import MyProLayout from "../../../components/MyProLayout";
import {ActionType, DrawerForm, ProFormCheckbox, ProFormText, ProTable} from "@ant-design/pro-components";
import FetchUtil from "../../../utils/FetchUtil";
import React, {useEffect, useRef, useState} from "react";
import {Divider, Form, message, Popconfirm, Space} from "antd";
import {DeleteOutlined, FormOutlined} from "@ant-design/icons";
import DataUtil from "../../../utils/DataUtil";
import FormUtil from "../../../utils/FormUtil";

const User = () => {

    const actionRef = useRef<ActionType>();
    const [formRef] = Form.useForm();

    // const [loading, setLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    // const [editing, setEditing] = useState<FunctionDo>();

    let isChanged = false;

    const [roleData, setRoleData] = useState<any[]>([]);

    useEffect(() => {
        loadRole().then();
    }, [])
    const loadRole = async () => {
        const res = await FetchUtil.awaitFetch('/adminApi/role/list', {});
        if (res.code === 200) {
            setRoleData(res.data.list);
        }
    }

    const columns: any[] = [
        {
            title: 'ID',
            key: 'userId',
            dataIndex: 'userId',
            align: 'center' as 'center',
            width: 1,
            hideInSearch: true,
        },
        {
            title: '登录名',
            key: 'loginName',
            dataIndex: 'loginName',
            width: 100,
            sorter: true,//{compare: (a: any, b: any) => a.loginName.localeCompare(b.loginName)},
            // defaultSortOrder: 'ascend' as 'ascend',
        },
        {
            title: '姓名',
            key: 'userName',
            dataIndex: 'userName',
            width: 100,
            sorter: true,//{compare: (a: any, b: any) => (a.userName || "").localeCompare(b.userName || "")}
        },
        {
            title: '角色',
            key: 'roleName',
            dataIndex: 'roleName',
            width: 100,
            valueType: "select",
            valueEnum: roleData.map(r => (r.roleName))
        },
        {
            title: '编辑',
            key: 'operation',
            dataIndex: 'operation',
            width: 100,
            align: 'center' as 'center',
            hideInSearch: true,
            render: (_: any, record: any) => {
                return (
                    record.loginName === "admin" ? <></> :
                        <Space size="small" split={<Divider type="vertical"/>}>
                            <a onClick={() => openEditor(record)}
                               key={'edit' + record.userId}><FormOutlined/></a>
                            <Popconfirm title="确定要删除这个用户吗"
                                        okText="删除"
                                        cancelText='再想想'
                                        onConfirm={() => deleteRecord(record.userId)}
                            >
                                <a key={'del' + record.userId}><DeleteOutlined/></a>
                            </Popconfirm>
                        </Space>
                )
            }
        },
        {
            title: '最近登录',
            key: 'lastLogin',
            dataIndex: 'lastLogin',
            width: 100,
            sorter: true,//{compare: (a: any, b: any) => a.lastLogin - b.lastLogin},
            valueType: "dateTime",
            hideInSearch: true
        },
        {
            title: '登录IP',
            key: 'lastIp',
            dataIndex: 'lastIp',
            width: 100,
            hideInSearch: true
        },
        {
            title: '修改日期',
            key: 'updateTime',
            dataIndex: 'updateTime',
            width: 100,
            valueType: "dateTime",
            hideInSearch: true
        },
    ];

    const deleteRecord = async (record_key: string) => {
        const res = await FetchUtil.awaitFetch('/adminApi/user/delete', [{userId: record_key}])
        if (res.code === 200) {
            message.success({content: '用户已删除！'});
            actionRef.current?.reload();
        }
    }

    const openEditor = async (r: any) => {
        formRef?.resetFields();
        const res = await FetchUtil.awaitFetch('/adminApi/user/getUserRole', {userId: r.userId});
        if (res.code === 200) {
            // console.log(res)
            r.roles = res.data;
            formRef.setFieldsValue(r)
            setOpenForm(true);
        } else {
            message.error(res.msg)
        }
    }
    const closeEditor = () => {
        const doClose = (open: boolean) => {
            setOpenForm(open);
            // setEditing(undefined);
        }
        FormUtil.close(isChanged, doClose);
        formRef?.resetFields();
    }
    const saveRecord = async (values: any) => {
        console.log(values);
        if (values.roles.length === 0) {
            message.error('请选择用户角色！');
            return;
        }
        const res = await FetchUtil.awaitFetch('/adminApi/user/setInfo', values);
        if (res.code === 200) {
            message.success('保存成功！');
            isChanged = false;
            closeEditor();
            actionRef.current?.reload();
        }
    }
    return (
        <MyProLayout>
            <ProTable
                actionRef={actionRef}
                columns={columns}
                rowKey="userId"
                cardBordered={true}
                // params 是需要自带的参数
                // 这个参数优先级更高，会覆盖查询表单的参数
                // params={mainQuery}
                request={async (
                    params,
                    sort,
                    // filter,
                ) => {
                    console.log(params, sort)
                    // let i = parseInt(params.roleName)
                    // let v = roleData[i]

                    const query = {
                        user: {...params, roleName: roleData[parseInt(params.roleName)]?.roleName},
                        page: {
                            pageNo: params.current,
                            pageSize: params.pageSize,
                            orderBy: DataUtil.sortToOrderBy(sort)
                        }
                    };
                    console.log(query)
                    const res:any = await FetchUtil.awaitFetch('/adminApi/user/list', query);
                    return {
                        success: true,
                        data: res.data.list,
                        total: res.data.total,
                    };
                }}
                // search={{span: 6}}
            />
            <DrawerForm
                width={666}
                open={openForm}
                colon={false}
                labelCol={{span: 4, offset: 0}}
                title={"人员账号"}
                layout="horizontal"
                drawerProps={{onClose: closeEditor, forceRender: true}}
                form={formRef}
                onFinish={saveRecord}
            >
                <ProFormText name="userId" hidden/>
                <ProFormText name="loginName" label="登录名"/>
                <ProFormText name="userName" label="真实姓名"/>
                <ProFormText.Password name="setPwd" label="重置密码"/>
                <ProFormText name="userEmail" label="Email"/>
                <ProFormText name="userMobile" label="手机号"/>
                <ProFormCheckbox.Group
                    layout={"vertical"}
                    name="roles"
                    label={"所属角色"}
                    options={roleData.map(r => ({
                        label: <span style={{lineHeight: '32px'}}>
                            <span className={"bold"}> {r.roleName}</span>
                            {r.remark ? `（${r.remark}）` : ''}
                        </span>,
                        value: r.roleId
                    }))}
                />
            </DrawerForm>
        </MyProLayout>
    )
}

export default User