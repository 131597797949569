import MyProLayout from "../../../components/MyProLayout";
import {ActionType, DrawerForm, ProCard, ProTable} from "@ant-design/pro-components";
import {Button, Col, Form, message, Modal, Row, Table} from "antd";
import MyRawData from "../../../components/MyRawData";
import React, {Key, useEffect, useRef, useState} from "react";
import * as Icon from "@ant-design/icons";
import {ArrowLeftOutlined, DownloadOutlined, ExpandOutlined} from "@ant-design/icons";
import {Log2Do} from "../../../model/log2Do";
import FetchUtil from "../../../utils/FetchUtil";
import moment from "moment/moment";
import SimpleList from "../../../api/SimpleList";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import Search from "antd/es/input/Search";

const Log2 = () => {

    const [formRef] = Form.useForm();
    const actionRef = useRef<ActionType>();

    const [loading, setLoading] = useState(false);
    const [viewDate, setViewData] = useState<Log2Do>({});
    const [openForm, setOpenForm] = useState(false);
    const [openComp, setOpenComp] = useState(false);
    const [compKey, setCompKey] = useState<string>();
    const [compData, setCompData] = useState<any[]>([]);
    const [logLevel, setLogLevel] = useState<any[]>();
    const {logType} = useParams();

    let navigate = useNavigate();

    useEffect(() => {
        actionRef.current?.reload();
    }, [logType]);

    useEffect(() => {
        SimpleList.param(1421).then((list) => {
            setLogLevel(list.map(r => {
                let icon = "";
                if (r.icon && r.icon !== "" && r.icon in Icon) {
                    let props: any = {style: {color: r.mappingCode}};
                    // @ts-ignore
                    icon = React.createElement(Icon[r.icon], props);
                }
                return {
                    value: r.code,
                    icon: icon,
                    text: <>{icon} {r.text}</>,
                }
            }))
        })
    }, [])

    const openView = async (id: number) => {
        setViewData({});
        setLoading(true);
        setOpenForm(true);
        const res = await FetchUtil.awaitFetch("/adminApi/log2/detail", {id: id});
        if (res.code === 200) {
            setViewData(res.data);
            // formRef.setFieldsValue(res.data)
        } else {
            message.error({content: res.msg});
        }
        setLoading(false);
    }

    const closeForm = () => {
        formRef.resetFields();
        setViewData({});
        setOpenForm(false);
    }
    const formatAccount = (v: string) => {
        if (v.includes("Email: ") && v.includes("Pwd: ")) {
            let v1 = v.split("Email: ")
            let v2 = v1[1].split("Pwd: ")
            return <>{v1[0]}
                Email: <span className="log2account">{v2[0]}</span> <br/>
                Pwd: <span className="log2account">{v2[1]}</span>
            </>;
        } else {
            return v;
        }
    }
    const columns: any[] = [
        {
            title: "ID",
            dataIndex: "id",
            align: "center" as "center",
            width: 40,
            hideInSearch: true,
        },
        {
            title: "Type",
            dataIndex: "type",
            width: 100,
            render: (v: string, r: any) => {
                return (<>
                    <span style={{marginRight: 8}}>
                        {logLevel?.find(l => Number.parseInt(l.value) === r.level)?.icon}
                    </span>
                    {v}
                </>)
            },
            initialValue: logType
        },
        {
            title: 'Level',
            dataIndex: 'level',
            valueType: 'select',
            valueEnum: logLevel,
            hideInTable: true
        },
        {
            title: "Key",
            dataIndex: "pk",
            width: 100,
        },
        {
            title: "Title",
            dataIndex: "title",
            width: 360,
            render: (v: string, r: any) => (
                r.type === "FC-AI" && r.clientIp && r.clientIp !== "server" && !r.clientIp.startsWith("job-")
                    ? <span className="blue">{formatAccount(v)}</span> : v
            )
        },
        {
            title: "Ts",
            dataIndex: "ts",
            width: 100,
            align: "center" as "center"
        },
        {
            title: "Time",
            dataIndex: "createTime",
            width: 160,
            align: "center" as "center",
            key: "createTime",
            valueType: "dateTime",
            hideInSearch: true,
        },
        {
            title: "Time",
            dataIndex: "createTime",
            key: "queryDate",
            valueType: "dateTimeRange",
            hideInTable: true,
            colSize: 2
        },
        {
            title: "User IP",
            dataIndex: "clientIp",
            width: 100,
            hideInSearch: true,
        },
        {
            title: "View",
            dataIndex: "id",
            key: "id_2",
            align: "center" as "center",
            width: 100,
            hideInSearch: true,
            render: (v: number) => (<a onClick={() => openView(v)}><span><ExpandOutlined/> 查看</span></a>),
        },
    ];


    const compColumns: any[] = [
        {
            title: " ",
            dataIndex: "label",
        },
        {
            title: "递送前",
            dataIndex: "value1",
            align: "right" as "right"

        },
        {
            title: "",
            dataIndex: "id1",
            render: (v: number) => <a onClick={() => openView(v)}><span><ExpandOutlined/> 查看</span></a>
        },
        {
            title: "递送后",
            dataIndex: "value2",
            align: "right" as "right"
        },
        {
            title: "",
            dataIndex: "id2",
            render: (v: number) => <a onClick={() => openView(v)}><span><ExpandOutlined/> 查看</span></a>
        },
    ]

    const onDeliveryCompare = async (v: string) => {
        if (v.length < 3) {
            message.warning("请输入有效订单号")
            return;
        }
        setOpenComp(true);
        setCompData([]);
        const res = await FetchUtil.awaitFetch("/mallApi/fc25/deliveryCompare", {origId: v});
        if (res.code === 200 && res.data.length > 0) {
            console.log("deliveryCompare",res.data);
            setCompData(res.data);
            setCompKey(res.data[0].key);
        }
    }
    const downloadPack = async () => {
        const res = await FetchUtil.awaitFetch("/mallApi/fc25/deliveryCompareDownload", compData);
        if (res.code === 200) {
            console.log(res.data);
            window.open(res.data);
        }
    }

    return (
        <MyProLayout>
            <ProTable
                actionRef={actionRef}
                columns={columns}
                rowKey="id"
                cardBordered={true}
                // params 是需要自带的参数
                // 这个参数优先级更高，会覆盖查询表单的参数
                // params={mainQuery}
                request={async (
                    params,
                    // sort,
                    // filter,
                ) => {
                    const query: any = {log2: params, page: {pageNo: params.current, pageSize: params.pageSize}};
                    if (params.queryDate) {
                        query.beginDate = Date.parse(params.queryDate[0]);
                        query.endDate = Date.parse(params.queryDate[1]);
                    }
                    const res = await FetchUtil.awaitFetch("/adminApi/log2/list", query);
                    return {
                        success: true,
                        data: res.data.list,
                        total: res.data.total,
                    };
                }}
                // search={{span: 6}}
                headerTitle={<>
                    <div hidden={!logType?.startsWith("py-") && !logType?.startsWith("job-")}>
                        <Button type='link' onClick={() => navigate(-1)}><ArrowLeftOutlined/> 返回</Button>
                    </div>
                    <div hidden={logType !== "FC-AI"}>
                        <Search placeholder="订单：递送前后数据对比" onSearch={onDeliveryCompare} enterButton allowClear />
                        </div>
                </>}
            />

            <Modal title={<>{compKey}: 递送前后数据对比</>}
                   open={openComp}
                   footer={null}
                   onCancel={()=>setOpenComp(false)}>
                <Table columns={compColumns} dataSource={compData} pagination={false} rowKey={"label"}/>
                <a onClick={downloadPack}>打包下载所有元数据 <DownloadOutlined /></a>
            </Modal>

            <DrawerForm
                drawerProps={{onClose: closeForm}}
                form={formRef}
                open={openForm}
                colon={false}
                labelCol={{span: 4, offset: 0}}
                title={"日志详情"}
                layout="horizontal"
                submitter={{render: () => null}}
            >
                <ProCard title={"ID " + viewDate?.id}
                         loading={loading}
                         bordered
                         headerBordered
                         collapsible>
                    <Row gutter={[24, 24]}>
                        <Col span={2}>Type</Col>
                        <Col span={10} className={"bold"}>{viewDate?.type}</Col>
                        <Col span={2}>Level</Col>
                        <Col span={10} className={"bold"}>{viewDate?.level}</Col>
                        <Col span={2}>Title</Col>
                        <Col span={22} className={"bold"}>{viewDate?.title}</Col>

                        <Col span={2}>Key</Col>
                        <Col span={10} className={"bold"}>{viewDate?.pk}</Col>
                        <Col span={2}>Time</Col>
                        <Col span={10}
                             className={"bold"}>{moment(viewDate?.createTime).format("yyyy-MM-DD HH:mm:ss")}</Col>

                        <Col span={2}>Ts</Col>
                        <Col span={10} className={"bold"}>{viewDate?.ts}</Col>
                        <Col span={2}>Dur</Col>
                        <Col span={10} className={"bold"}>{viewDate?.duration}</Col>

                        <Col span={2}>User</Col>
                        <Col span={10} className={"bold"}>{viewDate?.createName}</Col>

                        <Col span={2}>IP</Col>
                        <Col span={10} className={"bold"}>{viewDate?.clientIp}</Col>


                    </Row>
                </ProCard>
                {!loading && <>
                    &nbsp;
                    <MyRawData rawData={viewDate?.post} label="Post"/>
                    &nbsp;
                    <MyRawData rawData={viewDate?.result} label="Result"/>
                </>}

            </DrawerForm>

        </MyProLayout>
    )
}

export default Log2