
import {BaseInfoDo} from "../../../model/BaseInfoDo";
import { Popconfirm, Space} from "antd";
import {DeleteOutlined, FormOutlined} from "@ant-design/icons";

interface IProps {
    mode?: string,
    type?: string,
    typeEnum?: any[],
    deleter: (id: number) => void,
    editor: (r: BaseInfoDo) => void,
}

export const infoColumns = (props:IProps) => {
    let columns: any[] = [];
    switch (props.mode) {
        case "page":
            switch (props.type) {
                case "slider":
                    columns = [
                        {title: '主图',   dataIndex: 'image',   valueType: 'image'},
                        {title: '标题',   dataIndex: 'title',  },
                        {title: '内容摘要',dataIndex: 'resume', },
                        {title: '链接按钮', dataIndex: 'link', valueType: 'textArea',  hideInTable:true}
                    ]
                    break;
                case  "image":
                    columns = [
                        {title: '主图', dataIndex: 'image', width: 100, valueType: 'image'},
                        {title: '标题', dataIndex: 'title', width: 200},
                        {title: '链接', dataIndex: 'link', width: 200},
                    ]
                    break;
                case  "data":
                    columns = [
                        {title: '数据', dataIndex: 'title'},
                        {title: '单位', dataIndex: 'resume',},
                        {title: '说明', dataIndex: 'link',}
                    ];
                    break;
                default:
                    columns = [
                        {title: '标题', dataIndex: 'title',},
                        {title: '副标题', dataIndex: 'resume'},
                    ];
            }
            break;
        case "email":
            columns = [
                {title: '大类', dataIndex: 'groupName', valueType: "readonly", hideInSearch:true, hideInTable: true},
                {title: '分类', dataIndex: 'infoType',  valueType: "select", hideInSearch:true,hideInTable: true, valueEnum: props.typeEnum},
                {title: '说明', dataIndex: 'resume', width: 200},
                {title: '邮件标题', dataIndex: 'title', width: 200,  order: 100},
                {title: '邮件内容', dataIndex: 'contents', hideInTable: true, valueType: 'rich', height: 300, order: 100},
                {title: '映射', dataIndex: 'mappingCode', width: 200},
            ]
            break;
        case "game":
            break;
        case "delivery":
            break;
        case "text":
            columns = [
                {title: '大类', dataIndex: 'groupName', valueType: "readonly", hideInSearch:true, hideInTable: true},
                {title: '分类', dataIndex: 'infoType',  valueType: "select", hideInSearch:true,hideInTable: true, valueEnum: props.typeEnum},
                {title: '说明', dataIndex: 'resume', width: 200},
                {title: '文案内容', dataIndex: 'title', width: 200, valueType: 'textArea', height: 300, order: 100},
                {title: '映射', dataIndex: 'mappingCode', width: 200},
            ]
            break;
        default:
            columns = [
                {title: 'Lang', dataIndex: 'langCode', width: 100, hideInForm: true},
                {title: 'Group', dataIndex: 'groupName', width: 100},
                {title: 'Type', dataIndex: 'typeName', width: 100},
                {title: 'Image', dataIndex: 'image', width: 100, valueType: 'image'},
                {title: 'Title', dataIndex: 'title', width: 200, order: 100,},
                {title: 'Resume', dataIndex: 'resume', width: 200},
                {title: 'Contents', dataIndex: 'contents', width: 200, hideInTable: true, valueType: "rich"},
                {title: 'Mapping', dataIndex: 'mappingCode', width: 100}
            ]
            break;
    }
    columns = columns.map((col) => {
        if (col.valueType !== 'image') {
            col.render = (v: string, r: BaseInfoDo) =>
                <a onClick={() => props.editor(r)} key={col.dataIndex}>
                    {v}
                </a>;
        }
        return col;
    })
    columns = [
        {
            dataIndex: '_id',
            width: 40,
            align: 'center' as 'center',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: 'ID',
            dataIndex: 'id',
            width: 40,
            align: 'center' as 'center',
            hideInSearch: true,
            hideInForm: true
        },
        ...columns,
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            width: 160,
            valueType: 'dateTime',
            align: 'center' as 'center',
            hideInSearch: true,
            hideInForm: true
        },
        {
            title: '操作',
            dataIndex: '_opt',
            width: 100,
            align: 'center' as 'center',
            hideInSearch: true,
            hideInForm: true,
            render: (_1: any, r: any) =>
                <Space>
                    <a key={3} onClick={() => props.editor(r)}>
                        <FormOutlined/>
                    </a>
                    <Popconfirm title="确定要删除这条记录吗"
                                okText="删除"
                                cancelText={"再想想"}
                                onConfirm={() => props.deleter(r.id)}>
                        <a key={4}><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>
        }
    ]
    return columns;
}