import {ProFormText, ProFormUploadButton} from "@ant-design/pro-components";
import {UploadProps, Image, message} from "antd";
import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/lib";

interface IProps {
    name: string,
    label: string,
    value?: string,
    values?: string[],
    formRef: FormInstance<any>
    max?: number,
    span?: number,
    readonly?: boolean,
    required?: boolean,
    multiple?: boolean
}

const MyUploadImage = (props: IProps) => {

    const [preview, setPreview] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    useEffect(() => {
        console.log("MyUploadImage.props = ", props)
        if (props.value && props.value !== "") {
            setImageSrc(props.value);
            props.formRef?.setFieldsValue({
                [props.name + "_upload"]:
                    [{
                        status: 'done',
                        uid: props.value,
                        name: props.value,
                        url: props.value,
                        thumbUrl: props.value
                    }],
            })
        } else if (props.values && props.values.length > 0) {
            setImageSrc(props.values[0]);
            props.formRef?.setFieldsValue({
                [props.name + "_upload"]: props.values.map((img: string) => ({
                    status: 'done',
                    uid: img,
                    name: img,
                    url: img,
                    thumbUrl: img
                }))
            })
        } else {
            onImgRemove();
        }
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const onImgChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
        if (newFileList.length > 0) {
            const r = newFileList[0];
            if (r.status === "done" && r.response) {
                // console.log("onChange-done", newFileList);
                if (r.response.code === 200) {
                    const imgSrc = r.response.data;
                    setImageSrc(imgSrc);
                    props.formRef?.setFieldsValue({[props.name]: imgSrc})
                } else {
                    message.error(r.response.msg).then()
                }
            }
        } else {
        }
    };

    const onImgRemove = () => {
        setImageSrc("");
        props.formRef?.setFieldsValue({
            [props.name + "_upload"]: [],
            [props.name]: ""
        })
    }

    const onImgPreview = (file: any) => {
        if (file.url && file.url!.toLowerCase().indexOf(".pdf") > 0) {
            window.open(file.url);
        } else {
            setPreview(true);
        }
    };

    return (
        <div style={{display: 'inline-block', width: `${((props.span || 12) / 24) * 100}%`}}>
            <ProFormUploadButton disabled={props.readonly}
                                 labelCol={{span: 4 / ((props.span || 12) / 24)}}
                                 name={`${props.name}_upload`}
                                 label= {props.label}
                                 fieldProps={{
                                     name: 'file',
                                     listType: 'picture-card',
                                     onPreview: onImgPreview,
                                     onRemove: onImgRemove,
                                     multiple: props.multiple || false,
                                 }}
                                 icon={props.readonly ? "" : undefined}
                                 title={props.readonly ? "" : undefined}
                                 action={"/adminApi/files/upload"}
                                 max={props.max || 1}
                                 accept="image/*"
                                 onChange={onImgChange}
                                 formItemProps={{rules: [{required: true}]}}
            />
            <ProFormText name={props.name} hidden/>
            <div style={{height: 1, overflow: "hidden"}}>
                <Image preview={{
                    visible: preview,
                    src: imageSrc,
                    onVisibleChange: value => {
                        setPreview(value);
                    },
                }}/>
            </div>
        </div>
    )
}
export default MyUploadImage