import {DrawerForm, ProFormSelect, ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import {forwardRef, useImperativeHandle, useState} from "react";
import {Form, message, Tabs} from "antd";
import FormUtil from "../../../utils/FormUtil";
import FetchUtil from "../../../utils/FetchUtil";
import {BaseInfoDo} from "../../../model/BaseInfoDo";
import store from "store";
import MyUploadImage from "../../../components/MyUploadImage";
import MyWangEditor from "../../../components/MyWangEditor";
import {useAppContext} from "../../../AppContaxt";

// const defaultLanguage = store.get("defaultLang");

const InfoEditor = (props : any, ref: any) => {

    const [formRef] = Form.useForm();
    const [openForm, setOpenForm] = useState(false);

    const [info, setInfo] = useState<BaseInfoDo | undefined>({});
    const [infoMulti, setInfoMulti] = useState<BaseInfoDo[]>([]);
    const [language, setLanguage] = useState("en");

    const {appState, updateAppState} = useAppContext();
    const langData: any = appState.langData;
    const defaultLanguage = appState.defaultLang;

    let isChanged = false;

    useImperativeHandle(ref, () => {
        return {
            openEditor: (r: any) => openEditor(r)
        }
    });
    const openEditor = async (r: any) => {
        setLanguage(defaultLanguage)
        formRef.resetFields();

        let datas = [];
        if (r.id && r.id !== 0) {
            const res = await FetchUtil.awaitFetch("/adminApi/info/detailMulti",
                {id: (r.parentId !== null && r.parentId !== r.id) ? r.parentId : r.id, orderBy: "id"});
            if (res.code === 200 && res.data && res.data.length > 0) {
                datas = res.data;
                // Because there are some values from join and readonly in form
                // But don't let page's data replace the raw data from database
                r = {...r, ...datas[0]};
            }
        }else{
            r.langCode = defaultLanguage;
            datas = [r];
        }
        setInfoMulti(datas);
        setInfo(r);
        formRef.setFieldsValue(r);
        setOpenForm(true);
        isChanged = false;
    }

    const closeEditor = () => {
        const doClose = (open:boolean) => {
            setOpenForm(open);
            setInfo({});
        }
        FormUtil.close(isChanged, doClose);
    }

    const onTabChange = (key: string) => {
        let values = formRef.getFieldsValue();
        if (values.langCode === "en" && (!values.id || values.id === 0)) {
            message.error("请先保存主记录，再添加其他多语言记录！")
            return;
        }
        const oldInfos = infoMulti.map((r: any) => {
            if (r.langCode === language) {
                r = {...r, ...values};
            }
            return r;
        });
        let newInfo: any = infoMulti.find((r: any) => r.langCode === key)
        if (newInfo === undefined) {
            const base = infoMulti.find((r: any) => r.langCode === defaultLanguage);
            newInfo = {
                infoType: base?.infoType,
                categoryId: base?.categoryId,
                parentId: base?.id,
                langCode: key
            }
            oldInfos.push(newInfo);
        }
        setInfoMulti([...oldInfos]);
        setLanguage(key);
        setInfo(newInfo);
        formRef.resetFields();
        formRef.setFieldsValue(newInfo);
    }

    const submitForm = async (values: any) => {
        console.log(values);
        let editInfo;
        if (infoMulti === undefined || infoMulti.length === 0) {
            values.langCode = defaultLanguage;
            editInfo = [values];
        } else {
            editInfo = infoMulti.map((r: any) => {
                if (r.langCode === language) {
                    r = {...r, ...values};
                }
                return r;
            });

        }
        const res = await FetchUtil.awaitFetch('/adminApi/info/edit', editInfo);
        if (res.code === 200) {
            isChanged = false;
            closeEditor();
            message.success("保存成功！")
            if (props.callback) {
                props.callback();
            }
        }

    }

    return (
        <DrawerForm
            width={980}
            form={formRef}
            open={openForm}
            colon={false}
            labelCol={{span: 4, offset: 0}}
            title={"内容编辑"}
            layout="horizontal"
            grid={true}
            drawerProps={{onClose: closeEditor, forceRender: false}}
            onFinish={submitForm}
            onChange={() => isChanged = true}
        >
            <Tabs activeKey={language}
                  items={langData}
                  onChange={onTabChange}
                  style={{marginBottom: 24, width: '100%'}}
                  centered
                  hidden={!props.multiLanguage}
                  size={"small"} />
            {props.columns.map((col: any, i: number) => {
                if (!col.hideInForm) {
                    switch (col.valueType) {
                        case "dateTime":
                            return "";
                        case "select":
                            return <ProFormSelect key={i}
                                                  name={col.dataIndex}
                                                  label={col.title}
                                                  // options={props.typeData}
                                                  options={col.valueEnum}
                            />;
                        case "textArea":
                            return <ProFormTextArea key={i}
                                                    name={col.dataIndex}
                                                    label={col.title}
                                                    fieldProps={{style: {height: col.height}}}/>
                        case "rich":
                            return <MyWangEditor key={i}
                                                 name={col.dataIndex}
                                                 label={col.title}
                                                 value={(info as any)?.[col.dataIndex] || ""}
                                                 formRef={formRef}
                            />
                        case "image":
                            return <MyUploadImage key={i}
                                                  name={col.dataIndex}
                                                  label={col.title}
                                                  value={(info as any)?.[col.dataIndex] || ""}
                                                  formRef={formRef}
                            />
                        case "readonly":
                            return <ProFormText name={col.dataIndex} label={col.title} key={i} readonly/>
                        default:
                            return <ProFormText name={col.dataIndex} label={col.title} key={i}/>
                    }
                }else {
                    return ""
                }
            })}
            <ProFormText name={"id"} hidden/>
            <ProFormText name={"infoType"} hidden/>
            <ProFormText name={"categoryId"} hidden/>
        </DrawerForm>
    )
}
export default forwardRef(InfoEditor)
